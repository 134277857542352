<template>
  <div class="mx-auto container container--fluid">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="1500px">
      <template v-slot:activator="{ on }">
        <v-btn color="indigo darken-4" dark class="mb-2 ma-3" v-on="on" @click="iseditable = !iseditable">Schedule Company</v-btn>
        <v-btn color="blue" style="float:right;margin-bottom:10px" dark @click="exceldialog1 = !exceldialog1">Excel Import</v-btn>
        
        <v-dialog width="350" persistent v-model="exceldialog1">
           <v-card>
              <v-card-title class="c-title">Excel import</v-card-title>
              <v-card-text>
                <br />
                <v-row justify="end">
                  <a :href="url1"><v-icon large color="blue" class="icon-quick" title="Download">mdi-file-excel</v-icon></a>
                </v-row>
                <v-file-input v-model="excelfile1" label="Import Excel"></v-file-input>
              </v-card-text>
              <v-card-actions>
                <v-row class="pr-5" justify="end">
                  <v-btn @click="clear1()" text>cancel</v-btn>
                  <v-btn @click="importexcel()" dark color="blue">Upload</v-btn>
                </v-row>
              </v-card-actions>
           </v-card>
        </v-dialog>
                      
        <v-dialog width="1000" persistent v-model="excelerrordialog1">
            <v-card>
              <v-card-title class="c-title">
                <v-row justify="space-between">
                  <b>Excel Error</b> 
                  <download-excel class="mar" :data="notfoundstudent1" :fields="table_fields_excel" worksheet="Company_List" name="Company_notfound_List.xls">
                    <v-btn color="success" dark class="mb-2"><v-icon dark>mdi-file-excel</v-icon></v-btn>
                  </download-excel>
                  <v-btn icon dark @click="closeerrordialog1">
                    <v-icon color="black">mdi-close</v-icon>
                  </v-btn>
                </v-row>
              </v-card-title>
              <v-card-text>
                <br />
                <br />
                <table style="width:100%" v-if="Array.isArray(notfoundstudent1) && notfoundstudent1.length" class="pr-5" justify="center">
                  <thead>
                    <th style="font-size:35px;">Grno</th>
                    <th style="font-size:35px;">Error</th>
                  </thead>
                  <tbody >
                    <tr class="ma-5" v-for="(item,index) in notfoundstudent1" :key=index>
                      <td class="text-center"> {{item.Company}} </td>
                      <td  class="text-center red--text"> {{item.error}} </td>
                    </tr>
                  </tbody>
                </table>
              </v-card-text>
              <v-card-actions>
                <v-row class="pr-5" justify="end">
                  <v-btn @click="closeerrordialog1()" text>Close</v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
        </v-dialog>
      </template>
      <v-card>
          <v-form v-model="valid">
            <v-toolbar flat dense class="tc-title">
              <v-toolbar-title><span class="headline"> {{ formTitle }}</span></v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <span style="color: red;font-size: 12px;font-weight: 700;float: right;">* indicates mandatory fields</span>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="dialog = false"><v-icon color="black">mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="3" md="3" v-if="editedIndex == -1">
                    <v-autocomplete item-text="name" item-value="id" :items="company_list" v-model="editedItem.company" :rules="[(v) => !!v || 'required']" label="Company*" @change="getcode()"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" v-if="editedIndex != -1">
                    <v-text-field v-model="editedItem.company" disabled dense label="Company*"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" v-if="editedIndex == -1">
                    <v-autocomplete :items="ay_list" v-model="editedItem.academicyear" :rules="[(v) => !!v || 'required']" label="Academic Year*" @change="getcode(editedItem.company,editedItem.academicyear)"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" v-if="editedIndex != -1">
                    <v-select dense v-model="editedItem.academicyear" :items="ay_list" item-value="id" persistent-hint :rules="[(v) => !!v || 'required']" label="Academic Year*"></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" v-if="editedIndex == -1" >
                    <v-text-field  label="Company Code" disabled v-model="editedItem.company_code"> </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" v-if="editedIndex != -1">
                    <v-text-field  label="Company Code" disabled v-model="editedItem.company_code"> </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" v-if="editedIndex == -1">
                    <v-autocomplete :items="offering_list" v-model="editedItem.placementtype" @change="findinternshiptype()" :rules="[(v) => !!v || 'required']" label="Company Offering*"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" v-if="editedIndex != -1">
                    <v-select dense v-model="editedItem.placementtype" :items="offering_list" @change="findinternshiptype()" item-value="id" persistent-hint :rules="[(v) => !!v || 'required']" label="Company Offering*"></v-select>
                  </v-col>
                  <template v-if="internship_type_list != null">
                    <v-col cols="12" sm="3" md="3" v-if="editedIndex == -1">
                      <v-autocomplete :items="internship_type_list" v-model="editedItem.internshiptype" label="Internship Duration"></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" v-if="editedIndex != -1 && editedItem.placementtype =='Internship'">
                      <v-select dense v-model="editedItem.internshiptype" :items="internship_type_list" item-value="id" persistent-hint label="Internship Duration"></v-select>
                    </v-col>
                  </template>
                  <v-col cols="12" sm="3" md="3" v-if="editedIndex == -1">
                    <v-autocomplete :items="company_type_list" v-model="editedItem.companytype" :rules="[(v) => !!v || 'required']" label="Company Type*"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" v-if="editedIndex != -1">
                    <v-select :items="company_type_list" dense v-model="editedItem.companytype" item-value="id" persistent-hint :rules="[(v) => !!v || 'required']" label="Company Type*"></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" v-if="editedIndex == -1">
                    <v-autocomplete :items="company_level_list" v-model="editedItem.companylevel" :rules="[(v) => !!v || 'required']" label="Company Level*"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" v-if="editedIndex != -1">
                    <v-select dense :items="company_level_list" v-model="editedItem.companylevel" :rules="[(v) => !!v || 'required']" label="Company Level*" item-value="id" persistent-hint ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" v-if="editedIndex == -1 && editedItem.companylevel == 'Department Level'">
                    <v-autocomplete :items="departmentlist" v-model="editedItem.department" :rules="[(v) => !!v || 'required']" label="Department*"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" v-if="editedIndex != -1 && editedItem.companylevel == 'Department Level'">
                    <v-select dense :items="departmentlist" v-model="editedItem.department" :rules="[(v) => !!v || 'required']" label="Department*" item-value="id" persistent-hint ></v-select>
                  </v-col>
                  <!--
                  <v-col cols="6" sm="3" md="2"  v-if="editedIndex == -1">
                    <label>Upload Attachment :</label>
                    <v-file-input type="file" placeholder="Select Attachment" prepend-icon="mdi-file" ref="file" show-size required="true" clearable v-model="file"></v-file-input>
                  </v-col>
                  <v-col cols="6" sm="3" md="2">
                    <v-checkbox  v-model="editedItem.isoffertobeacceptedbystudent" color="success" label="Offer Choice to Student">
                      <input type="hidden" v-model="editedItem.studofferid" name="studofferid"/>
                    </v-checkbox>
                    <v-checkbox  v-model="editedItem.iscvrequired" color="success" label="Is cv required">
                      <input type="hidden" v-model="editedItem.studcvid" name="studcvid"/>
                    </v-checkbox>
                  </v-col>
                  -->
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="primary darken-1" @click="save" :disabled="!valid">Save</v-btn>
              </v-card-actions>
            </v-card-text>
          </v-form>
      </v-card>
    </v-dialog>

 <v-dialog width="900" persistent v-model="dialogreschedule">
           <v-card>
              <v-card-title class="c-title">Reschedule</v-card-title>
              <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="3" md="3" v-if="editedIndex == -1">
                            <v-text-field v-model="rescheduleitem.company" disabled dense label="Company*"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" v-if="editedIndex == -1">
                        <v-autocomplete :items="ay_list" v-model="rescheduleitem.academicyear" :rules="[(v) => !!v || 'required']" label="Academic Year*" @change="getcode(rescheduleitem.company_id,rescheduleitem.academicyear)"></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" v-if="editedIndex == -1">
                          <v-text-field v-model="rescheduleitem.company_code" disabled dense label="CompanyOfferingCode*"></v-text-field>
                      </v-col>
                      <v-col>
                      
                          <v-btn @click="reschedulecompanyoffering()" dark color="blue">Copy</v-btn>
                      </v-col>
                      </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row class="pr-5" justify="end">
                  <v-btn @click="closereschedule()" text>Cancel</v-btn>
                  
                </v-row>
              </v-card-actions>
           </v-card>
        </v-dialog>
    <v-card style="padding: 10px">
      <div style="border: 3px solid blue !important; padding: 10px">
        <v-row>
          <v-col sm="2">
            <v-autocomplete :items="fetchaylist" v-model="fetch_filter.ay" :rules="[(v) => !!v || 'required']" label="Academic Year"></v-autocomplete>
          </v-col>
          <v-col sm="2">
            <v-autocomplete :items="fetchcompany_list" v-model="fetch_filter.company" :rules="[(v) => !!v || 'required']" label="Company" item-text="name" item-value="id"></v-autocomplete>
          </v-col>
          <v-col sm="2">
            <v-autocomplete :items="fetchofferinglist" v-model="fetch_filter.offering" :rules="[(v) => !!v || 'required']" label="Placement Type"></v-autocomplete>
          </v-col>
          <v-col sm="2">
            <v-autocomplete :items="company_offering_status" v-model="fetch_filter.status" label="Offering Status"></v-autocomplete>
          </v-col>
          <v-col sm="2">
            <v-autocomplete :items="fetchcompanytypelist" v-model="fetch_filter.company_type" :rules="[(v) => !!v || 'required']" label="Company Type"></v-autocomplete
          ></v-col>
          <v-col sm="2">
            <v-autocomplete :items="fetchcompanylevellist" v-model="fetch_filter.company_level" :rules="[(v) => !!v || 'required']" label="Company Level"></v-autocomplete
          ></v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <center>
              <v-btn color="indigo darken-4" dark class="mb-2" v-on="on" @click="fetch()" dense> Fetch</v-btn>
            </center>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <br />
    <v-app id="inspire" class>
      <template v-if="load == true">
        <v-data-table hide-default-footer disable-pagination :headers="headers" group-by="companylevel" :items="offer_company_list" class="elevation-1" :search="search">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Company Scheduling</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
              <span style="width: 40px"></span>
              <v-toolbar flat dense>
                <download-excel v-if="offer_company_list.length > 0" class="mr-2 pb-3 pt-2" :data="offer_company_list" :fields="table_fields" worksheet="Compnay_Scheduling_Info" name="Compnay_Scheduling_Info.xls">
                  <v-btn color="success" outlined rounded><v-icon dark>mdi-file-excel</v-icon> Excel</v-btn>
                </download-excel>
              </v-toolbar>
            </v-toolbar>
          </template>
          <!-- <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row>
                <v-col cols="12" sm="3" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Company Name :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.company }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black; ">
                  <span style="color: black; font-size: 12px; font-weight: normal">Academic Year :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.academicyear }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black; ">
                  <span style="color: black; font-size: 12px; font-weight: normal">Company code :
                  </span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.company_code }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black; ">
                  <span style="color: black; font-size: 12px; font-weight: normal">Semester :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.semester }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Offering : </span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.placementtype }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Internship Duration :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.internshiptype }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Company type :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.companytype }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Company Level :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.companylevel }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Domain :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">
                    <span v-for = "(i,index) in item.industry" :key=index>{{i}} <br></span>
                  </span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Min Package/Stipend :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.minPackage }} </span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Max Package/Stipend :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.maxPackage }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Job Description :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.job_description }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Job Location :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{item.locations}}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Placement Process Location :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.placement_process_locations }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Company Person Name :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.contact_person_name }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Company Person Email :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.contact_email }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Company Person Contact :</span>
                  <br>
                  <span style="color: blue; font-size: 14px; font-weight: bold">{{ item.contact_phone }}</span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Allowed Institutes :</span>
                  <br />
                  <span v-if="item.organization.length == 1" style="color: blue; font-size: 14px; font-weight: bold">{{ item.organization[0] }}.</span>
                  <span v-else style="color: blue; font-size: 14px; font-weight: bold">
                    <div v-for="(org, index) in item.organization" :key="index">
                      <span v-if="item.organization.length == index + 1"> {{ org }}.</span>
                      <span v-else> {{ org }}, </span>
                    </div>
                  </span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Required Skills :</span>
                  <br />
                  <span v-if="item.skill.length == 1" style="color: blue; font-size: 14px; font-weight: bold">{{ item.skill[0] }}.</span>
                  <span v-else style="color: blue; font-size: 14px; font-weight: bold">
                    <div v-for="(skill, index) in item.skill" :key="index">
                      <span v-if="item.skill.length == index + 1"> {{ skill }}.</span>
                      <span v-else> {{ skill }}, </span>
                    </div>
                  </span>
                </v-col>
                <v-col cols="12" sm="4" md="4" style="border: 1px solid black;">
                  <span style="color: black; font-size: 12px; font-weight: normal">Allowed Branches :</span>
                  <br />
                  <span v-if="item.tpoprogram.length == 1" style="color: blue; font-size: 14px; font-weight: bold"> {{ item.tpoprogram[0] }}.</span>
                  <span v-else style="color: blue; font-size: 14px; font-weight: bold">
                    <div v-for="(prog, index) in item.tpoprogram" :key="index">
                      <span v-if="item.tpoprogram.length == index + 1"> {{ prog }}.</span>
                      <span v-else> {{ prog }}, </span>
                    </div>
                  </span>
                </v-col>
              </v-row>
            </td>
          </template> -->
          <template v-slot:item.regstartdatetime="{ item }"> {{item.regStartdate}} {{item.regStarttime}}</template>
          <template v-slot:item.regenddatetime="{ item }"> {{item.regEnddate}}  {{item.regEndtime}} </template>
          <template v-slot:item.isactive="{ item }">
            <v-switch v-if="item.editrightpresent" v-model="item.isactive" @change="activate(item)" :labels="{checked:'On',unchecked:'Off'}"></v-switch>
            <v-switch v-else v-model="item.isactive" disabled="true" :labels="{checked:'On',unchecked:'Off'}"></v-switch>
          </template>
          <template v-slot:item.action="{ item }">
          <div>
              <v-avatar v-if="item.editrightpresent" style size="25" class="edit-avatar">
                 <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
              </v-avatar>
              <v-btn  v-if="item.question =='available' || item.oncampus =='available'" text> <v-icon></v-icon></v-btn>
              <v-btn  v-else text> <v-icon color="red" @click="deleteItem(item.id)"> mdi-delete</v-icon></v-btn>
          </div>       
          </template>
           <template v-slot:item.reschedule="{ item }">
              <v-btn color="primary" @click="reschedule(item)"> Reschedule</v-btn>
          </template>

          <!-- <template v-slot:item.addattachment="{ item }">
               <span style="font-size:30px;color:black; cursor: pointer;" class="mdi mdi-attachment mdi-rotate-90" data-toggle="tooltip" data-placement="bottom" title="Add Attachment" @click="addattachment(item)"></span>
          </template>
          <template v-slot:item.addcriteria="{ item }">
              <v-btn v-if="item.criteria =='available'" color="green" @click="addcriteria(item)"> Criteria</v-btn>
              <v-btn v-else color="red" @click="addcriteria(item)"> Criteria </v-btn>
          </template>
          <template v-slot:item.process="{ item }">
              <v-btn v-if="item.rounds =='available'" color="green" @click="addprocedure(item)"> Rounds </v-btn>
              <v-btn v-else color="red" @click="addprocedure(item)"> Rounds</v-btn>
          </template> -->
          <template v-slot:item.campus="{ item }">
              <v-btn v-if="item.oncampus =='available'" color="green" @click="companyoncampus(item)"> On Campus({{item.applicationcount}})</v-btn>
              <v-btn v-else color="red" @click="companyoncampus(item)"> On Campus({{item.applicationcount}})</v-btn>
          </template>
          <!-- <template v-slot:item.addprogram="{ item }">
              <v-btn v-if="item.programcount =='available'" color="green" @click="addprogram(item)"> Program </v-btn>
              <v-btn v-else color="red" @click="addprogram(item)"> Program</v-btn>
          </template> -->
          <template v-slot:item.addquestions="{ item }">
              <v-btn v-if="item.question =='available'" color="green" @click="addquestions(item)"> Questions({{item.questioncount}})</v-btn>
              <v-btn v-else color="red" @click="addquestions(item)"> Questions({{item.questioncount}})</v-btn>
          </template>

         
        </v-data-table>
        <div v-if="isloadmorevisible==true">
            <v-row>
          <v-col sm="12">
            <center>
              <v-btn color="indigo darken-4" dark class="mb-2" v-on="on" @click="fetch()" dense> Load more</v-btn>
            </center>
          </v-col>
        </v-row>
        </div>
      </template>
      <template v-else>
          <v-skeleton-loader
          class="ma-3 pa-2"
          v-if="true"
          v-bind="attrs"
          type="list-item-three-line"
        ></v-skeleton-loader>
      </template>
    </v-app>
    <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import datetime from "vuejs-datetimepicker";
import Collapse from "vue-collapse";
import readXlsxFile from "read-excel-file";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import * as lang from "vuejs-datepicker/src/locale";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  components: { datetime, Datepicker, Collapse },
  data: () => ({
    excelfile1: null,
    exceldialog1: false, 
    url1: "",
    file:null,
    excelerrordialog1: false,
    notfoundstudent1: [],
    singleExpand: false,
    expanded: [],
    packageDisabled:false,
    valid: false,
    overlay: false,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    iseditable: false,
    load: false,
    dialog: false,
    dialogreschedule:false,
    offer_company_list: [],
    company_list: null,
    offering_list: null,
    ay_list: null,
    sem_list: null,
    industry_list: null,
    company_type_list: null,
    company_level_list: null,
    departmentlist : null,
    search: "",
    skill_list: null,
    tpoprogram_list: null,
    internship_type_list: null,
    org_list: null,
    year_list:null,
    city_list:null,
     isloadmorevisible:false,
    table_fields_excel: {
      "Sr. No.": "srno",
      "Company": "Company",
      "Error": "error",
    },
    table_fields: {
      "Company": "company",
      "Academic Year": "academicyear",
      "Semester": "semester",
      "Offering": "placementtype",
      "Internship Duration": "internshiptype",
      "Company Type": "companytype",
      "Company Level": "companylevel",
      "Department": "department",
      "Domain": "industry",
      "Job Location": "locations",
      "Job Description":"job_description",
      "Min Package In Lakhs":"minPackage",
      "Max Package In Lakhs":"maxPackage",
      "From Schedule Date": "from_schedule_date",
      "To Schedule Date": "to_schedule_date",
      "Required Skills": "skill",
      "Allowed Branches": "tpoprogram",
      "Allowed Institutes": "organization",
      "Contact Person Name": "contact_person_name",
      "Contact Person Email": "contact_email",
      "Contact Person Phone": "contact_phone",
      "Placement Process Locations": "placement_process_locations"
    },

    headers: [
      {
        text: "Company",
        value: "company",
      },
      {
        text: "Registration start Date",
        value: "regstartdatetime",
      },
      {
        text: "Registration end Date",
        value: "regenddatetime",
      },
      // {
      //   text: "Department",
      //   value: "department",
      // },
      {
        text: "Status",
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
      
      {
        text: "",
        value: "campus",
        sortable: false,
      },
      {
        text: "",
        value: "addquestions",
        sortable: false,
      },
      {
        text: "",
        value: "reschedule",
        sortable: false,
      },
    ],

    editedIndex: -1,
    editedItem: {
      id: 0,
      studofferid: 0,
      regStarttime:"",
      regEndtime:"",
      company: "",
      academicyear: "",
      semester: "",
      placementtype: "",
      companytype: "",
      companylevel: "",
      department:"",
      industry: "",
      locations: "",
      job_description: "",
      minPackage: 0,
      maxPackage: 0,
      from_schedule_date: "",
      to_schedule_date: "",
      regStartdate:"",
      regEnddate:"",
      skill: "",
      tpoprogram: "",
      programnew:"",
      organization: "",
      contact_person_name: "",
      contact_email: "",
      contact_phone: "",
      internshiptype: "",
      placement_process_locations: "",
      isactive: false,
      isoffertobeacceptedbystudent: true,
      iscvrequired: true,
      skill_required: "",
      tpoprogram: "",
      year:"",
      organization: "",
      internship: "",
      isLiveBacklogAllowed: false,
      company_code:"",
    },

     rescheduleitem: {
      company: "",
      academicyear: "",
      company_code:"",
      company_id:"",
       prev_companyoffering_id:"",
    },
    
    defaultItem: {
      id: 0,
      studofferid: 0,
      company: "",
      academicyear: "",
      semester: "",
      placementtype: "",
      companytype: "",
      companylevel: "",
      department : "",
      industry: "",
      locations: "",
      minPackage: "",
      maxPackage: "",
      job_description: "",
      from_schedule_date: "",
      to_schedule_date: "",
      skill: "",
      tpoprogram: "",
      organization: "",
      year:"",
      contact_person_name: "",
      contact_email: "",
      contact_phone: "",
      placement_process_locations: "",
      isactive: true,
      isoffertobeacceptedbystudent: true,
      iscvrequired: true,
      internshiptype: "",
      skill_required: "",
      tpoprogram: "",
      organization: "",
      internship: "",
      isLiveBacklogAllowed: false,
      company_code:"",
    },
    date: new Date().toISOString().substr(0, 10),
    date1: new Date().toISOString().substr(0, 10),
    menu1: false,
    mennu2: false,
    fetch_filter: {
      ay: "",
      offering: "",
      domain: "",
      from_date: "",
      to_date: "",
      company_type: "",
      company_level: "",
      status:"",
      company : "",
      max:30,
      offset:0,
    },
    menu: false,
    menu2: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    mobileRules: [
      (v) => !!v || "Mobile No is required",
      (v) => (v && v.length <= 10) || "Number must be less than 10 digits",
    ],

    fetchaylist: [],
    fetchofferinglist: [],
    fetchcompanytypelist: [],
    fetchcompanylevellist: [],
    fetchcompany_list:[],
    fetchindustrylist: [],
    company_offering_status:[],
    iscurretay: "",
    fetchfromdate: new Date().toISOString().substr(0, 10),
    fetchtodate: new Date().toISOString().substr(0, 10),
    data : {
      id : '',
      studofferid: '',
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Schedule Company"
        : "Edit Company Scheduling";
    },

    allbranches() {
      if(this.editedIndex == -1){
      return this.editedItem.tpoprogram.length === this.tpoprogram_list.length
      }
      else if(this.editedIndex != -1){
       return this.editedItem.programnew.length === this.tpoprogram_list.length
      }
    },

    somebranches () {
      return this.editedItem.tpoprogram.length > 0 && !this.allbranches
    },

    icon () {
      if (this.allbranches) return 'mdi-close-box'
      if (this.somebranches) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  mounted() {
    this.fetch_filter.max=30;
    this.fetch_filter.offset=0;
    this.onLoad();
  },

  methods: {
      importexcel() {
        var schedulinglist=[];
        readXlsxFile(this.excelfile1).then((rows) => {
        //console.log(rows);
        var i;
        for (i = 1; i < rows.length; i++) {
            var temp={};
            temp['Company_Name']=rows[i][0];
            temp['Academic_Year']=rows[i][1];
            temp['Semester']=rows[i][2];
            temp['CompanyOffering']=rows[i][3];
            temp['CompanyType']=rows[i][4];
            temp['CompanyLevel']=rows[i][5];
            temp['Department']=rows[i][6];
            temp['HR_name']=rows[i][7];
            temp['HR_contact']=rows[i][8];
            temp['HR_email']=rows[i][9];
            schedulinglist.push(temp);
        }          
        var params={"schedulinglist":schedulinglist};
         axios.post("/TPOCompanyScheduling/saveSchedulingByexcel",params).then((res) => {
          if (res.data.msg == "200") {
            //console.log(res.data.errorlist.length)
            if(res.data.errorlist.length!=0){
                this.excelerrordialog1=true;
                this.notfoundstudent1=res.data.errorlist;
            }else{
              this.showSnackbar("green", "Success.."); 
            }         
          } else {
            this.showSnackbar("red", res.data.msg); 
          }
        });
      });
      this.exceldialog1=false;
    },

    clear1() {
      this.excelfile1 = null;
      this.exceldialog1 = false;
    },
    
    closeerrordialog1() {
      this.clear1();
      this.excelerrordialog1 = false;
    },
    
    addprogram(item){
      localStorage.setItem("code",item.company_code)
      localStorage.setItem("ay",item.academicyear)
      localStorage.setItem("companyname",item.company)
      localStorage.setItem("scheduling_id",item.id)
      this.$router.push({
          name:"tpo-add-program",
          params:{scheduling_id: item.id, cmp_name: item.company,item: item,code:item.company_code,ay:item.academicyear}
      })
    },

    addquestions(item){
      localStorage.setItem("code",item.company_code)
      localStorage.setItem("ay",item.academicyear)
      localStorage.setItem("companyname",item.company)
      localStorage.setItem("scheduling_id",item.id)
      let routerData=this.$router.resolve({
          name:"admin-master-offeringquestion",
          query:{scheduling_id: item.id, cmp_name: item.company,item: item,code:item.company_code,ay:item.academicyear}
      });
      window.open(routerData.href, '_blank');
    },

    getcode(company,ay){
     // alert("get code ")
      if(company&&ay){
        var params={"company_id":company,"ay":ay}
        axios
          .post("/TPOCompanyScheduling/getcode",params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.editedItem.company_code=res.data.code;
              this.rescheduleitem.company_code= res.data.code;
            }
        });
      }
    },

    toggleBranches(){
      if(this.editedIndex == -1){
          this.$nextTick(() => {
            if (this.allbranches) {
              this.editedItem.tpoprogram = []
            } else {
              this.editedItem.tpoprogram = this.tpoprogram_list.slice()
            }
         })
      }else if(this.editedIndex != -1){
        this.$nextTick(() => {
          if(this.allbranches) {
            this.editedItem.programnew = []
          } else {
            this.editedItem.programnew = this.tpoprogram_list.slice()
          }
        })
      }
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    formatDate1(date1) {
      if (!date1) return null;
      const [year, month, day] = date1.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate1(date1) {
      if (!date1) return null;
      const [month, day, year] = date1.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    deleteItem(item){
      const data = {
                    deleteitem: item,
                };
                axios
                    .post("/TPOCompanyScheduling/deleteCompanyScheduling", data)
                    .then((res) => {
                        //console.log("inside axios  deleteItem")
                        if (res.data.code == "SUCCESS") {
                            
                            this.showSnackbar("#4caf50", "Company Offering Deleted Successfully...");
                            this.onLoad()
                        } 
                        else if (res.data.code == "failed") {
                            
                            this.showSnackbar("#4caf50", res.data.message);
                        }
                        else {
                            //error
                            this.showSnackbar("#b71c1c", "Could Not Delete Company offering" );
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    })
                    this.close();
    },

    editItem(item) {
      this.iseditable = false;
      this.editedIndex = this.offer_company_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.company=item.company
      if(this.editedItem.placementtype == 'Internship'){
        this.findinternshiptype()
      }
      if(item.from_schedule_date && item.to_schedule_date){
        this.editedItem.from_schedule_date= item.from_schedule_date.split(" ")[0].trim()
        this.editedItem.to_schedule_date= item.to_schedule_date.split(" ")[0].trim()
      }
      if(item.regStartdate && item.regEnddate){
        if(item.regStartdatenew){
          this.editedItem.regStartdate = item.regStartdatenew.split("T")[0].trim()
        }
        if(item.regEnddatenew){
          this.editedItem.regEnddate =  item.regEnddatenew.split("T")[0].trim()
        }
      }
      this.editedItem.locations=item.locations
      this.dialog = true;  
    },

     reschedule(item) {
     // alert("hii");
      //console.log(item.id)
      this.iseditable = false;
      this.rescheduleitem.company=item.company;
      this.rescheduleitem.company_id=item.company_id;
      this.rescheduleitem.prev_companyoffering_id = item.id;
      // console.log("-----------")
      // console.log(this.rescheduleitem.prev_companyoffering_id)
      this.rescheduleitem.company_code="";
      this.rescheduleitem.academicyear="";
      this.dialogreschedule = true;  
    },
    closereschedule(){
      this.dialogreschedule=false;
    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    addcriteria(item) {
      localStorage.setItem("companyname",item.company)
      localStorage.setItem("scheduling_id",item.id)
      this.$router.push({
        name: "tpo-create-criteria",
        params: { scheduling_id: item.id, cmp_name: item.company , item:item, code:item.company_code },
      });
    },

    companyoncampus(item) {
      localStorage.setItem("scheduling_object",item)
      localStorage.setItem("companyname",item.company)
      localStorage.setItem("scheduling_id",item.id)
      let routeData = this.$router.resolve({
        name: "tpo-company-campus-process",
        query: { scheduling_id: item.id, cmp_name: item.company, item: item },
      });
      window.open(routeData .href, '_blank');
    },

    addattachment(item){
      localStorage.setItem("scheduling_object",item)
      localStorage.setItem("companyname",item.company)
      localStorage.setItem("scheduling_id",item.id)
      this.$router.push({
          name:"tpo-add-attachment",
          params:{scheduling_id: item.id, companyname: item.company,scheduling_object: item}
      })
    },

    addprocedure(item) {
      localStorage.setItem("scheduling_object",item)
      localStorage.setItem("companyname",item.company)
      localStorage.setItem("scheduling_id",item.id)
      this.$router.push({
        name: "selection-procedure",
        params: { scheduling_id: item.id, companyname: item.company, scheduling_object: item },
      });
    },

    findinternshiptype() {
      const data = {
        internship: this.editedItem.placementtype,
      };
      axios
        .post("/TPOCompanyScheduling/getplacementtype", data)
        .then((res) => {
          //window.console.log(res);
          if (res.data.msg == "200") {
            this.internship_type_list = res.data.internship_type_list;
            
            } else {
            this.message = res.data.msg;
            this.internship_type_list=null;
          }
        })
        .catch((error) => {
          window.console.log("error:" + error);
        })
        .finally(() => (this.fetch_org_load = false));
    },

    reschedulecompanyoffering() {
      //console.log(this.rescheduleitem);
      
      axios
        .post("/TPOCompanyScheduling/reschedulecompanyoffering", this.rescheduleitem)
        .then((res) => {
          //window.console.log(res);
          if (res.data.status.code == "SUCCESS") {
            this.dialogreschedule=false;
            this.rescheduleitem.company_code="";
            this.editedItem.company_code="";
            this.showSnackbar(
                        "#4caf50",
                        "Offered Company Successfully..."
                      ); 

            } else {
            
          }
        })
        .catch((error) => {
          window.console.log("error:" + error);
        })
        .finally(() => (this.fetch_org_load = false));
    },

    save() {
      if(this.editedItem.contact_phone){
         if(this.editedItem.contact_phone.length == 10){
            if (this.editedIndex > -1) {
                this.iseditable = false;
                axios
                  .post("/TPOCompanyScheduling/editComapnyScheduling", this.editedItem)
                  .then((res) => {
                    if (res.data.msg == "200") {
                      this.onLoad();
                      this.showSnackbar(
                        "#4caf50",
                        "Offered Company Updated Successfully..."
                      ); 
                    }
                  })
                  .catch((error) => {
                      this.showSnackbar("red", "Offered Company Not Updated...");
                      //window.console.log(error);
                  });
            } else {
                let formData = new FormData();
                // formData.append("file", this.file);
                formData.append("company",this.editedItem.company);
                formData.append("company_code",this.editedItem.company_code);
                formData.append("academicyear",this.editedItem.academicyear);
                formData.append("semester",this.editedItem.semester);
                formData.append("placementtype",this.editedItem.placementtype);
                formData.append("companytype",this.editedItem.companytype);
                formData.append("companylevel",this.editedItem.companylevel);
                formData.append("department",this.editedItem.department);
                formData.append("industry",this.editedItem.industry);
                formData.append("locations",this.editedItem.locations);
                formData.append("job_description",this.editedItem.job_description);
                formData.append("minPackage",this.editedItem.minPackage);
                formData.append("maxPackage",this.editedItem.maxPackage);
                formData.append("from_schedule_date",this.editedItem.from_schedule_date);
                formData.append("to_schedule_date",this.editedItem.to_schedule_date);
                if(this.editedItem.regStartdate)
                  formData.append("regStartdate",this.editedItem.regStartdate);
                if(this.editedItem.regEnddate)
                  formData.append("regEnddate",this.editedItem.regEnddate);
                if(this.editedItem.regStarttime)
                  formData.append("regStarttime",this.editedItem.regStarttime);                 
                if(this.editedItem.regEndtime)
                  formData.append("regEndtime",this.editedItem.regEndtime);
                
                formData.append("skill",this.editedItem.skill);
                formData.append("tpoprogram",this.editedItem.tpoprogram);
                formData.append("programnew",this.editedItem.programnew);
                formData.append("organization",this.editedItem.organization);
                formData.append("year",this.editedItem.year);
                formData.append("contact_person_name",this.editedItem.contact_person_name);
                formData.append("contact_email",this.editedItem.contact_email);
                formData.append("contact_phone",this.editedItem.contact_phone);
                formData.append("internshiptype",this.editedItem.internshiptype);
                formData.append("placement_process_locations",this.editedItem.placement_process_locations);
                formData.append("isactive",this.editedItem.isactive);
                // formData.append("isoffertobeacceptedbystudent",this.editedItem.isoffertobeacceptedbystudent);
                // formData.append("iscvrequired",this.editedItem.iscvrequired);
                formData.append("skill_required",this.editedItem.skill_required);
                formData.append("internship",this.editedItem.internship);
                formData.append("isLiveBacklogAllowed",this.editedItem.isLiveBacklogAllowed);
              
                axios
                  .post("/TPOCompanyScheduling/saveComapnyScheduling",formData, {
                      headers: {
                          "Content-Type": "multipart/form-data",
                      },})
                    .then((res) => {
                        if (res.data.status.code == "SUCCESS") {
                          this.onLoad();
                          this.showSnackbar("#4caf50", "New Company Offered Successfully..."); 
                        } else if (res.data.status.code == "NA") {
                          this.showSnackbar("#b71c1c", "Company Already Present!!!"); 
                        }
                  })
                  .catch((error) => {
                    window.console.log(error);
                  });
            }
            this.close();
         }else{
            this.showSnackbar("#b71c1c", "Please enter 10 digit contact number!!!");
         }
      }else{
        if (this.editedIndex > -1) {
              this.iseditable = false;
              axios
                .post("/TPOCompanyScheduling/editComapnyScheduling", this.editedItem)
                .then((res) => {
                  if (res.data.msg == "200") {
                    this.onLoad();
                    this.showSnackbar("#4caf50","Offered Company Updated Successfully..."); 
                  }
                })
                .catch((error) => {
                  this.showSnackbar("red", "Offered Company Not Updated...");
                    //window.console.log(error);
                });
        } else {
            let formData = new FormData();
            // formData.append("file", this.file);
            formData.append("company",this.editedItem.company);
            formData.append("company_code",this.editedItem.company_code);
            formData.append("academicyear",this.editedItem.academicyear);
            formData.append("semester",this.editedItem.semester);
            formData.append("placementtype",this.editedItem.placementtype);
            formData.append("companytype",this.editedItem.companytype);
            formData.append("companylevel",this.editedItem.companylevel);
            formData.append("department",this.editedItem.department);
            formData.append("industry",this.editedItem.industry);
            formData.append("locations",this.editedItem.locations);
            formData.append("job_description",this.editedItem.job_description);
            formData.append("minPackage",this.editedItem.minPackage);
            formData.append("maxPackage",this.editedItem.maxPackage);
            formData.append("from_schedule_date",this.editedItem.from_schedule_date);
            formData.append("to_schedule_date",this.editedItem.to_schedule_date);
            if(this.editedItem.regStartdate)
               formData.append("regStartdate",this.editedItem.regStartdate);
            if(this.editedItem.regEnddate)
               formData.append("regEnddate",this.editedItem.regEnddate);
            if(this.editedItem.regStarttime)
               formData.append("regStarttime",this.editedItem.regStarttime);                 
            if(this.editedItem.regEndtime)
               formData.append("regEndtime",this.editedItem.regEndtime);
            
            formData.append("skill",this.editedItem.skill);
            formData.append("tpoprogram",this.editedItem.tpoprogram);
            formData.append("programnew",this.editedItem.programnew);
            formData.append("organization",this.editedItem.organization);
            formData.append("year",this.editedItem.year);
            formData.append("contact_person_name",this.editedItem.contact_person_name);
            formData.append("contact_email",this.editedItem.contact_email);
            formData.append("contact_phone",this.editedItem.contact_phone);
            formData.append("internshiptype",this.editedItem.internshiptype);
            formData.append("placement_process_locations",this.editedItem.placement_process_locations);
            formData.append("isactive",this.editedItem.isactive);
            // formData.append("isoffertobeacceptedbystudent",this.editedItem.isoffertobeacceptedbystudent);
            // formData.append("iscvrequired",this.editedItem.iscvrequired);
            formData.append("skill_required",this.editedItem.skill_required);
            formData.append("internship",this.editedItem.internship);
            formData.append("isLiveBacklogAllowed",this.editedItem.isLiveBacklogAllowed);
            axios
              .post("/TPOCompanyScheduling/saveComapnyScheduling",formData, {
              headers: {
                  "Content-Type": "multipart/form-data",
              },} )
              .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                this.onLoad();
                this.showSnackbar("#4caf50","New Company Offered Successfully..."); 
              } else if (res.data.status.code == "NA") {
                this.showSnackbar("#b71c1c", "Company Already Present!!!"); 
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
        }
        this.close();
      }
    },

    onLoad() {
      localStorage.removeItem("scheduling_object");
      localStorage.removeItem("companyname");
      localStorage.removeItem("scheduling_id");
    this.overlay = true;
      if (this.fetch_filter.offering && this.fetch_filter.status && this.fetch_filter.company_type && this.fetch_filter.company_level && this.fetch_filter.company) {
        this.fetch();
        this.overlay = false;
      } else {
        axios
          .post("/TPOCompanyScheduling/offerComapnyScheduling")
          .then((res) => {
            if (res.data.msg == "200") {
              this.fetchaylist = res.data.fetch_ay_list.sort();
              this.fetchofferinglist = res.data.fetch_offering_list;
              this.fetchcompanytypelist = res.data.fetch_company_type_list.sort();
              this.fetchcompanylevellist = res.data.fetch_company_level_list.sort();
              this.fetchcompany_list = res.data.fetchcompany_list;
              this.fetchindustrylist = res.data.fetch_industry_list.sort();
              this.company_offering_status=res.data.company_offering_status.sort();
              this.iscurretay = res.data.iscurrentay;
              this.fetchfromdate = res.data.fetch_from_date;
              this.url1 = res.data.url1;
              this.fetchtodate = res.data.fetch_to_date;
              this.fetch_filter.ay = res.data.currentay;
              this.fetch_filter.status = 'All';
              this.fetch_filter.offering = this.fetchofferinglist[0];
              this.fetch_filter.domain = this.fetchindustrylist[0];
              this.fetch_filter.from_date = this.fetchtodate;
              this.fetch_filter.to_date = this.fetchfromdate;
              this.fetch_filter.company_type = this.fetchcompanytypelist[0];
              this.fetch_filter.company_level = res.data.defaultaccess;
              this.fetch_filter.company = this.fetchcompany_list[0].id;
              this.offer_company_list = res.data.offer_company_list;
              this.newLocation = res.data.offer_company_list.locations
              this.company_list = res.data.company_list.sort();
              this.offering_list = res.data.offering_list;
              this.ay_list = res.data.ay_list;
              this.sem_list = res.data.sem_list;
              this.industry_list = res.data.industry_list;
              this.company_type_list = res.data.company_type_list;
              this.company_level_list = res.data.fetch_company_level_list;
              this.editedItem.companylevel =  res.data.defaultaccess;
              this.editedItem.companytype=  this.company_type_list[1]
                                    // console.log("level 2-------"+res.data.fetch_company_level_list)
              this.departmentlist = res.data.departmentlist;
              this.year_list = res.data.year_list;
                (this.skill_list = res.data.skills_list),
                (this.tpoprogram_list = res.data.program_list),
                (this.org_list = res.data.org_list),
                this.city_list = res.data.city_list,
                (this.load = true);
               
            }
            this.overlay = false;
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            //window.console.log(error);
            this.overlay = false;
          });
      }
    },

    setdatetodate() {
      editedItem.to_schedule_date = editedItem.from_schedule_date;
    },

    activate(item){
      this.data.id = item.id,
      axios
        .post("/TPOCompanyScheduling/activateComapnyScheduling", this.data)
        .then((res) =>{
          if (res.data.msg == "200") {
            this.onLoad();
            this.showSnackbar("green", res.data.message); // show snackbar
          }
        });
    },
    
    fetch() {
      this.overlay = true;
      axios
        .post("/TPOCompanyScheduling/offerComapnyScheduling", this.fetch_filter)
        .then((res) => {
          if (res.data.msg == "200") {
            this.fetchaylist = res.data.fetch_ay_list.sort();
            this.fetchofferinglist = res.data.fetch_offering_list.sort();
            this.fetchcompanytypelist = res.data.fetch_company_type_list.sort();
            this.fetchcompanylevellist = res.data.fetch_company_level_list.sort();
            this.fetchcompany_list = res.data.fetchcompany_list;
            this.fetchindustrylist = res.data.fetch_industry_list.sort();
            this.iscurretay = res.data.iscurrentay;
            this.fetchfromdate = res.data.fetch_from_date;
            this.fetchtodate = res.data.fetch_to_date;

            this.fetch_filter.ay = res.data.fetch_ay_value;
            this.fetch_filter.offering = res.data.fetch_offering_value;
            this.fetch_filter.domain = res.data.fetch_industry_value;
            this.fetch_filter.from_date = res.data.fetch_to_date_value;
            this.fetch_filter.to_date = res.data.fetch_from_date_value;
            this.fetch_filter.company_type = res.data.fetch_comapny_type_value;
            this.fetch_filter.company_level = res.data.fetch_comapny_level_value;
            this.fetch_filter.company = res.data.fetch_comapny_value;
            this.offer_company_list=this.offer_company_list.concat(res.data.offer_company_list);
            this.isloadmorevisible=res.data.isloadmorevisible;
            this.company_list = res.data.company_list.sort();
            this.offering_list = res.data.offering_list;
            this.ay_list = res.data.ay_list;
            this.sem_list = res.data.sem_list;
            this.industry_list = res.data.industry_list;
            this.company_type_list = res.data.company_type_list;
            this.company_level_list = res.data.fetch_company_level_list;
            this.departmentlist = res.data.departmentlist;
            (this.skill_list = res.data.skills_list),
              (this.tpoprogram_list = res.data.program_list),
              (this.org_list = res.data.org_list),
              (this.load = true);
               this.fetch_filter.max=res.data.max;
                this.fetch_filter.offset=res.data.max+res.data.offset;
          } 
          this.overlay = false;
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          //window.console.log(error);
          this.overlay = false;
        });
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
.required {
  color: red;
  font-size: 14px;
  font-style: bold;
}

.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}
</style>

